<template>
  <div>
    <!-- select 2 demo -->
    <b-modal id="modal-report" centered ok-only size="sm" no-close-on-backdrop hide-footer="true" title="Reported by">
      <b-row v-for="data in reportObj" :key="data.id" style="margin: 5px 0px; align-items: center;">
        <b-avatar :src="data.avatar" size="45" class="mr-1" />
        <div>
          <h6>{{ data.name }}</h6>
          <h6>{{ data.role }}</h6>
        </div>
      </b-row>
    </b-modal>
    <b-modal id="modal-members" centered ok-only size="sm" no-close-on-backdrop hide-footer="true" title="Members">
      <b-row v-for="data in memberObj" :key="data.id" style="margin: 6px 0px; align-items: center;">
        <b-avatar :src="data.avatar" size="45" class="mr-1" />
        <div>
          <h6>{{ data.name }}</h6>
          <h6>{{ data.role }}</h6>
        </div>
      </b-row>
    </b-modal>

    <b-modal id="modal-login" centered ok-only no-close-on-backdrop hide-footer="true" title="Community">
      <b-form>
        <b-row>
          <b-col>
            <b-form-group ref="image" label="Upload Profile" invalid-feedback="Profile pic is required.">
              <b-media no-body>
                <div v-if="logoloading == true">
                  <b-spinner variant="primary" type="grow" class="m-5" label="Spinning"></b-spinner>
                </div>

                <b-media-aside>
                  <b-link>
                    <b-img ref="previewEl" rounded
                      src="https://dk0pm9zdlq16s.cloudfront.net/5be0a5b2-5eaf-4090-8528-4be800d41de0.jpg"
                      @click="$refs.fileProfile.click()" v-if="logoloading == false"
                      style="height: 150px; width: 150px; object-fit: cover" />

                    <!-- <img v-if= "loading==false" rounded :src="require('@/assets/images/elements/Unicorn-Cake.jpg')" @click="$refs.files.click()" style="width: 7vw; height: 7vw; padding-left:20px; padding-top:20px;" alt="imgAdd"/>  -->
                  </b-link>

                  <b-link v-if="logoloading == 'loaded'">
                    <div>
                      <i @click="deleteLogo()" style="margin-top: 70px; margin-left: 70px"
                        class="fa fa-trash fa-md text-danger position-absolute"></i>
                    </div>
                    <b-img rounded :src="plus" style="height: 150px; width: 150px; object-fit: cover" />
                    <br />
                  </b-link>

                  <input type="file" id="fileProfile" hidden ref="fileProfile" @change="selectlogo()" accept="images/*" />
                </b-media-aside>
              </b-media>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="text-center">
          <b-col>
            <small class="text-muted">Recommended pixels are 850 x 950</small>
          </b-col>
        </b-row>
        <b-form-group label="Name" invalid-feedback="community name is required." ref="name">
          <b-form-input id="name-input" ref="name" placeholder="Enter community name here." required
            @focusout="CheckName()" v-model="myObj.name" />
        </b-form-group>
        <b-form-group label="Description" invalid-feedback="description is required." ref="details">
          <b-form-input id="name-input" ref="details" placeholder="Enter detail here." required @focusout="CheckDetail()"
            v-model="myObj.description" />
        </b-form-group>
        <b-form-group label="Memebers Limit" invalid-feedback="members limit is required." ref="limit">
          <b-form-input type="number" id="name-input" ref="limit" placeholder="Enter members limit here." required
            @focusout="CheckLimit()" v-model="myObj.limit" />
        </b-form-group>
        <b-form-group label="Profile privacy" invalid-feedback="profile privacy is required." ref="privacy">
          <v-select ref="privacy" placeholder="Select privacy." v-model="myObj.visiblity"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="privacyOptions" label="text"
            :reduce="(option) => option.value" :clearable="false" @input="CheckPrivacy()" />
        </b-form-group>
        <!-- 
        <b-form-group
          label="Status"
          invalid-feedback="Status is required."
          ref="status"
        >
          <v-select
            placeholder="Select status."
            v-model="myObj.status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusOptions"
            :clearable="false"
          />
        </b-form-group> -->
      </b-form>
      <br />
      <div style="">
        <b-button class="float-right ml-5" variant="primary" @click="AddFlavour()">
          <b-spinner v-if="request" variant="light" type="grow" small label="Spinning"></b-spinner>
          <span v-if="!request" class="text-nowrap">Save</span>
        </b-button>
      </div>
    </b-modal>
    <b-modal id="modal-reject" centered ok-only no-close-on-backdrop hide-footer="true" title="Reason">
      <b-form>
        <b-form-group label="Reason">
          <b-form-textarea id="textarea-default" placeholder="Enter the reason of rejection." rows="3" v-model="reason" />
        </b-form-group>
      </b-form>
      <br />
      <div style="">
        <b-button class="float-right ml-5" variant="primary" @click="Rejected()">
          <span class="text-nowrap">Send</span>
        </b-button>
      </div>
    </b-modal>

    <!-- <b-form-group label="Search User" invalid-feedback="Search field">
      <b-form-input
        id="name-input"
        ref="name"
        placeholder="Enter user name or email to search"
        required
        @input="SearchData()"
        v-model="searchQuery"
      />
    </b-form-group> -->

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row align-h="end" style="align-items: center ;">
          <b-col class="" cols="*" lg="*" md="*" sm="*">
            <b-button variant="warning" @click="LoadData()" style="margin-left: 10px;">
              <span class="text-nowrap">
                All
                <b-badge variant="danger" class="badge-glow" v-if="allCount != 0">
                  {{ allCount }}
                </b-badge></span>
            </b-button>
            <b-button variant="success" @click="LoadFiltered('active')" style="margin-left: 10px;">
              <span class="text-nowrap">Active
                <b-badge variant="danger" class="badge-glow" v-if="activeCount != 0">
                  {{ activeCount }}
                </b-badge></span>
            </b-button>
            <b-button variant="info" @click="LoadFiltered('pending')" style="margin-left: 10px;">
              <span class="text-nowrap">Pending
                <b-badge variant="danger" class="badge-glow" v-if="pendingCount != 0">
                  {{ pendingCount }}
                </b-badge></span>
            </b-button>
            <b-button variant="secondary" @click="LoadFiltered('rejected')" style="margin-left: 10px;">
              <span class="text-nowrap">Rejected
                <b-badge variant="danger" class="badge-glow" v-if="rejectCount != 0">
                  {{ rejectCount }}
                </b-badge></span>
            </b-button>
            <b-button variant="danger" @click="LoadReported()" style="margin-left: 10px;">
              <span class="text-nowrap">Reported
                <b-badge variant="secondary" class="badge-glow" v-if="reportCount != 0">
                  {{ reportCount }}
                </b-badge></span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-table :items="items" :fields="fields" striped v-if="!reportedGrid">
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(profilePic)="data">
            <!-- {{data.item }} -->
            <b-img :src="data.item.community.profilePic" style="width: 100px; height: 100px; object-fit: cover" rounded
              alt="Rounded image" />
          </template>
          <template #cell(name)="data">
            {{ data.item.community.name }}
          </template>
          <template #cell(description)="data">
            {{ data.item.community.description }}
          </template>
          <template #cell(limit)="data">
            {{ data.item.community.limit }}
          </template>
          <template #cell(visiblity)="data">
            {{ data.item.community.visiblity }}
          </template>

          <template #cell(actions)="data">
            <template v-if="data.item.community.status == 'active'">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="ml-1 btn-icon"
                v-b-tooltip.hover.v-primary v-b-tooltip.placement.left title="Edit"
                @click="OpenAddModal(data.item.community.id)">
                <feather-icon size="16" icon="EditIcon" />
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="ml-1 btn-icon"
                v-b-tooltip.hover.v-primary v-b-tooltip.placement.left title="Show Members"
                @click="ShowMembers(data.item.community)">
                <feather-icon size="16" icon="UserIcon" />
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="ml-1 btn-icon"
                v-b-tooltip.hover.v-primary v-b-tooltip.placement.left title="Suspend"
                @click="RejectCommunity(data.item.community)">
                <feather-icon size="16" icon="XIcon" />
              </b-button>

              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-danger" class="ml-1 btn-icon"
                v-b-tooltip.hover.v-danger v-b-tooltip.placement.right title="Delete"
                @click="DeleteFlavour(data.item.community.id)">
                <feather-icon size="16" icon="Trash2Icon" />
              </b-button>
            </template>

            <template v-if="data.item.community.status == 'pending'">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="ml-1 btn-icon"
                v-b-tooltip.hover.v-primary v-b-tooltip.placement.left title="Approve"
                @click="ApproveCommunity(data.item.community)">
                <feather-icon size="16" icon="CheckIcon" />
              </b-button>

              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="ml-1 btn-icon"
                v-b-tooltip.hover.v-primary v-b-tooltip.placement.left title="Reject"
                @click="RejectCommunity(data.item.community)">
                <feather-icon size="16" icon="XIcon" />
              </b-button>
            </template>

            <template v-if="data.item.community.status == 'rejected'">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="ml-1 btn-icon"
                v-b-tooltip.hover.v-primary v-b-tooltip.placement.left title="Approve"
                @click="ApproveCommunity(data.item.community)">
                <feather-icon size="16" icon="CheckIcon" />
              </b-button>

              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="ml-1 btn-icon"
                v-b-tooltip.hover.v-primary v-b-tooltip.placement.left title="Show Members"
                @click="ShowMembers(data.item.community)">
                <feather-icon size="16" icon="UserIcon" />
              </b-button>
            </template>
          </template>
        </b-table>

        <b-table :items="items" :fields="fields2" striped v-else>
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(profilePic)="data">
            <!-- {{data.item }} -->
            <b-img :src="data.item.profilePic" style="width: 100px; height: 100px; object-fit: cover" rounded
              alt="Rounded image" />
          </template>
          <template #cell(actions)="data">
            <template>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="ml-1 btn-icon"
                v-b-tooltip.hover.v-primary v-b-tooltip.placement.left title="Show Report"
                @click="ShowReport(data.item.id)">
                <feather-icon size="16" icon="AlertCircleIcon" />
              </b-button>

              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-danger" class="ml-1 btn-icon"
                v-b-tooltip.hover.v-primary v-b-tooltip.placement.left title="Suspend"
                @click="RejectCommunity(data.item)">
                <feather-icon size="16" icon="XIcon" />
              </b-button>
            </template>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalOrders" :per-page="perPage" first-number last-number
                class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BModal,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BSpinner,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    //Multiselect,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BModal,
    BMedia,
    BMediaAside,
    BSpinner,
    BImg,
    BMedia,
    BFormGroup,
    BAvatar,
    BLink,
    BBadge,
    //BDropdown,
    //BDropdownItem,
    BPagination,
    BFormTextarea,
    vSelect,
    //flatPickr,
    //'gallery': VueGallery
  },
  props: {
    /* modalFilter: {
      type: [String, null],
      default: null,
      },
      modalFilterOptions: {
      type: Array,
      required: true,
      }*/
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  created() {
    this.LoadData();
  },
  data() {
    return {
      reason: "",
      items2: [],
      fields2: [
        "#",
        { label: "profile", key: "profilePic" },
        { label: "Name ", key: "name" },
        { label: "description", key: "description" },
        { label: "limit", key: "limit" },
        { label: "visiblity", key: "visiblity" },
        "actions",
      ],
      IsPending: false,
      privacyOptions: [
        { text: "Public", value: "public" },
        { text: "Private", value: "private" },
      ],
      request: false,
      venueses: [],
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJPc2FtYSIsImVtYWlsIjoib3NhbWF1c21hbjU1NUBnbWFpbC5jb20iLCJqdGkiOiJjMzI0MGI0ZS1mM2NlLTQxZGUtYmRmNS1iYTE1YTFjMjkyZDIiLCJleHAiOjE2NzA1NjU2MjQsImlzcyI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20iLCJhdWQiOiJhcHBpY2tzb2x1dGlvbnMuaW8uY29tIn0.0_pK3DJcXTTEAk-fFziiMOkgGUC7nUYXppJ9C7dOIic",
      plus:
        "https://appick.io/u/lessons/64a03015-aaab-44a2-85c7-fc83ad9417b7.png",
      fileProfile: "",
      logoloading: false,
      errors: {
        status: false,
      },
      statusOptions: ["Active", "Inactive", "Pending"],
      statusOptionsFilter: ["user", "vendor"],
      filterData: "user",
      date: {
        range: "",
      },
      index: null,
      cover: "",
      docs: [],
      partners: [],
      fields: [
        "#",
        { label: "profile", key: "profilePic" },
        { label: "Name ", key: "name" },
        { label: "description", key: "description" },
        { label: "limit", key: "limit" },
        { label: "members", key: "members" },
        { label: "visiblity", key: "visiblity" },
        "actions",
        // { label: "Status", key: "status" },
      ],
      items: [],
      selected: null,
      filterOptions: [
        { text: "Most sold product", value: "mostSold" },
        { text: "Least sold product", value: "leastSold" },
        { text: "Most popular product", value: "mostPopular" },
        { text: "Least popular product", value: "leastPopular" },
        { text: "Most profitable product", value: "mostProfitable" },
      ],
      rangeDate: null,
      // myObj: {
      //   id: 0,
      //   profilePic: "",
      //   name: "",
      //   limit: 0,
      //   visiblity: "",
      //   details: "",
      // },
      myObj: {},
      detailObj: {},
      searchQuery: "",
      allCount: 0,
      pendingCount: 0,
      activeCount: 0,
      rejectCount: 0,
      reportCount: 0,
      reportedGrid: false,
      reportObj: {},
      memberObj: {},
    };
  },
  methods: {
    SearchData() {
      if (this.searchQuery.length > 0) {
        var data = this.items.filter(
          (data) =>
            JSON.stringify(data)
              .toLowerCase()
              .indexOf(this.searchQuery.toLowerCase()) !== -1
        );
        this.items = data;
      } else {
        this.LoadData();
      }
    },
    Rejected() {
      this.myObj.reason = this.reason;
      var axios = require("axios");
      // this.$store.state.userData.userID
      var config = {
        method: "put",
        url: "https://api.geotalent.co/api/" + this.myObj.id,
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
        data: this.myObj,
      };

      axios(config)
        .then((response) => {
          if (response.data.status === "success") {
            this.$bvModal.hide("modal-reject");
            console.log(response.data);
            this.LoadData();

            this.$bvToast.toast("Reason sended.", {
              title: "Success!",
              variant: "success",
              toaster: "b-toaster-bottom-center",
            });
          } else {
            this.$bvToast.toast("Something went wrong.", {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-bottom-center",
            });
          }
          //  this.data  =  response.data;
          //console.log(this.$store.state.userData.userID);
          //this.items = response.data;

          // console.log(this.items);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    RejectVenue(item) {
      this.myObj.id = item.id;
      this.myObj.name = item.name;
      this.myObj.sequence = item.sequence;
      this.myObj.vendorID = item.vendorID;
      this.myObj.status = "Active";
      this.myObj.iconID = item.iconID;
      this.myObj.reason = item.reason;
      this.$bvModal.show("modal-reject");
    },
    ApproveVenue(item) {
      console.log(item);
      this.myObj.id = item.id;
      this.myObj.name = item.name;
      this.myObj.sequence = 0;
      this.myObj.vendorID = 0;
      this.myObj.status = "Active";
      this.myObj.iconID = item.iconID;
      this.myObj.reason = item.reason;
      //return console.log(this.myObj);

      var axios = require("axios");
      // this.$store.state.userData.userID
      var config = {
        method: "put",
        url: "https://api.geotalent.co/api/" + this.myObj.id,
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
        data: this.myObj,
      };

      axios(config)
        .then((response) => {
          if (response.data.status === "success") {
            this.$bvModal.hide("modal-login");
            console.log(response.data);
            this.LoadData();

            this.$bvToast.toast("Venue updated.", {
              title: "Success!",
              variant: "success",
              toaster: "b-toaster-bottom-center",
            });
          } else {
            this.$bvToast.toast("Something went wrong.", {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-bottom-center",
            });
          }
          //  this.data  =  response.data;
          //console.log(this.$store.state.userData.userID);
          //this.items = response.data;

          // console.log(this.items);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    FilterTypes() {
      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://api.geotalent.co/api/" + this.filterStatus,
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          this.items = response.data.data;
          // response.data.data.forEach(e => {
          //   e.marketplaceShare = e.marketplaceShare * 100;
          //   this.items.push(e);
          // });

          // console.log(this.items);
          //console.log(this.venueses);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // this.$store.state.userData.userID

    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.name == "" || this.myObj.name.length < 3) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckImage() {
      var elem = this.$refs["image"];
      if (this.myObj.profilePic == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCategory() {
      console.log(this.myObj.iconID);
      var elem = this.$refs["icon"];
      if (
        this.myObj.iconID == 0 ||
        this.myObj.iconID == undefined ||
        this.myObj.iconID == null
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDetail() {
      var elem = this.$refs["details"];
      if (this.myObj.description == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckPrivacy() {
      console.log(this.myObj.visiblity);
      var elem = this.$refs["privacy"];
      if (this.myObj.visiblity == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckLimit() {
      var elem = this.$refs["limit"];
      if (this.myObj.limit == "" || this.myObj.limit == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    OpenAddModal(id) {
      // this.$bvModal.show("modal-login");
      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://api.geotalent.co/api/Communities/" + id,
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          console.log(response);
          this.myObj = response.data.data;
          this.plus = this.myObj.profilePic;
          this.logoloading = "loaded";
          this.$bvModal.show("modal-login");
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    OpenEditModal(row) {
      this.$router.replace({ name: "apps-occasions", params: { id: row.id } });
      // this.$bvModal.show('modal-login');
      // this.myObj.id = row.id;
      // this.myObj.name = row.name;
      // this.myObj.iconID = row.iconID;
      // this.myObj.status = row.status;
      // this.myObj.vendorID = row.vendorID;
      // this.myObj.sequence = row.sequence;
    },
    OpenDetails(row) {
      this.$router.replace({
        name: "apps-orderstatus",
        params: { id: row.id },
      });
    },
    DeleteFlavour(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require("axios");
          var config = {
            method: "delete",
            url: "https://api.geotalent.co/api/Communities/" + id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
          };
          console.log(config.url);
          axios(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === "success") {
                Swal.fire(
                  "Success!",
                  "Community has been deleted.",
                  "success"
                ).then((res) => {
                  this.LoadData();
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    selectlogo() {
      this.fileProfile = this.$refs.fileProfile.files[0];
      var axios = require("axios");
      //console.log(this.fileProfile.name);

      //console.log(this.fileProfile, this.loading );
      //Upload cover
      if (this.fileProfile !== "") {
        this.logoloading = true;
        let formData = new FormData();
        formData.append("file", this.fileProfile);
        console.log(this.fileProfile);
        axios
          .post("https://geoupload.appick.io/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })

          .then((e) => {
            console.log(e.data);
            // this.file = undefined;
            var fn1 = e.data.myresp[0].path;
            console.log(fn1);
            // console.log(fn);
            this.plus = fn1;
            this.myObj.profilePic = this.plus;
            //
            console.log(this.plus);
            this.logoloading = "loaded";
            this.CheckImage();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteLogo() {
      //console.log(0);
      this.myObj.profilePic = "";
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    ApproveCommunity(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Approve it!",
      }).then((result) => {
        if (result.isConfirmed) {
          item.status = "active";
          console.log(item);
          var axios = require("axios");
          var config = {
            method: "put",
            url: "https://api.geotalent.co/api/Communities/" + item.id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: item,
          };
          console.log(config.url);
          axios(config)
            .then((response) => {
              console.log(response.data);
              if (response.data.status === "success") {
                Swal.fire(
                  "Success!",
                  "Community has been approved.",
                  "success"
                ).then((res) => {
                  this.LoadData();
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    RejectCommunity(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Reject it!",
      }).then((result) => {
        if (result.isConfirmed) {
          item.status = "rejected";
          console.log(item);
          var axios = require("axios");
          var config = {
            method: "put",
            url: "https://api.geotalent.co/api/Communities/" + item.id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: item,
          };
          console.log(config.url);
          axios(config)
            .then((response) => {
              console.log(response.data);
              if (response.data.status === "success") {
                Swal.fire(
                  "Success!",
                  "Community has been rejected.",
                  "success"
                ).then((res) => {
                  this.LoadData();
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    AddFlavour() {
      this.CheckDetail();
      this.CheckName();
      this.CheckImage();
      this.CheckLimit();
      this.CheckPrivacy();
      if (
        this.CheckDetail() == false ||
        this.CheckName() == false ||
        this.CheckImage() == false ||
        this.CheckLimit() == false ||
        this.CheckPrivacy() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-bottom-center",
        });
      } else {
        console.log(this.myObj);
        //Edit
        this.request = true;
        var axios = require("axios");
        var config = {
          method: "put",
          url: "https://api.geotalent.co/api/Communities/" + this.myObj.id,
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
          data: this.myObj,
        };

        axios(config)
          .then((response) => {
            if (response.data.status === "success") {
              console.log(response.data);
              this.request = false;
              this.$bvModal.hide("modal-login");
              this.LoadData();
              this.$bvToast.toast("Community updated.", {
                title: "Success!",
                variant: "success",
                toaster: "b-toaster-bottom-center",
              });
            } else {
              this.$bvToast.toast("Something went wrong.", {
                title: "Error!",
                variant: "danger",
                toaster: "b-toaster-bottom-center",
              });
              this.request = false;
            }
          })
          .catch(function(error) {
            console.log(error);
            this.request = false;
          });
      }
    },

    LoadData() {
      var axios = require("axios");
      var config = {
        method: "get",
        url: `https://api.geotalent.co/api/Communities/LoadCommunitiesWeb?status=`,
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          console.log(response);
          this.reportedGrid = false;
          this.items = [];
          this.items = response.data.data;
          this.allCount = this.items.length;
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    LoadFiltered(status) {
      console.log(status);
      var axios = require("axios");
      var config = {
        method: "get",
        url:
          `https://api.geotalent.co/api/Communities/LoadCommunitiesWeb?status=` +
          status,
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          console.log(response);
          this.reportedGrid = false;
          this.items = response.data.data;
          if (status == "active") {
            this.activeCount = this.items.length;
          } else if (status == "pending") {
            this.pendingCount = this.items.length;
          } else {
            this.rejectCount = this.items.length;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    LoadReported() {
      var axios = require("axios");
      var config = {
        method: "get",
        url: `https://api.geotalent.co/api/Communities/ReportedCommunities`,
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          console.log(response);
          this.reportedGrid = true;
          this.items = response.data.data;
          this.reportCount = this.items.length;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    ShowReport(id) {
      var axios = require("axios");
      var config = {
        method: "get",
        url:
          `https://api.geotalent.co/api/Communities/LoadReports?communityID=` +
          id,
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          console.log(response);
          this.reportObj = response.data.data;
          this.$bvModal.show("modal-report");
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    ShowMembers(item) {
      var axios = require("axios");
      var config = {
        method: "get",
        url:
          `https://api.geotalent.co/api/Communities/LoadMembers?communityID=` +
          item.id +
          "&profileID=" +
          item.profileID,
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          console.log(response);
          this.memberObj = response.data.data;
          this.$bvModal.show("modal-members");
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}

h6 {
  margin-bottom: 0.2rem;
}
</style>
